/* eslint-disable class-methods-use-this */
import mapboxgl, { GeolocateControl, Map, NavigationControl, StyleSpecification } from "@iventis/mapbox-gl";
import { Feature, FeatureCollection, Geometry } from "geojson";
import bbox from "@turf/bbox";
import { StyleType } from "@iventis/domain-model/model/styleType";
import { MapboxModelLayer } from "@iventis/map-engine/src/bridge/mapbox-layers/mapbox-model-layer";
import { MapboxEngineData, MapModuleLayer, MapState } from "@iventis/map-engine";
import { initialState } from "@iventis/map-engine/src/state/map-state.initial-values";
import { Model } from "@iventis/domain-model/model/model";
import { ModelAsset, ModelDataStore } from "@iventis/map-engine/src/data-store/model-data-store";
import { LocalGeoJson } from "@iventis/map-types";
import { DataFieldListItemStore } from "@iventis/map-engine/src/data-store/data-field-list-item-store";
import { MapboxLineModelLayer } from "@iventis/map-engine/src/bridge/mapbox-layers/mapbox-line-model-layer";
import { BehaviorSubject } from "rxjs";
import { mapboxKey } from "../constants/mapbox-constants";
import { IventisLayer } from "../types/layer-types";
import { MapboxSupportedLayers } from "../types/mapbox-types";
import trianglePNG from "../assets/triangle.png";
import { iventisLogo } from "../constants/iventis-logo";
import { MapObjectProperties } from "../types/export-types";
import "@iventis/mapbox-gl/dist/mapbox-gl.css";

mapboxgl.accessToken = mapboxKey;

/** Basic mapbox class to show geojson */
export class Mapbox {
    public map: Map;

    private resizeMapObserver: ResizeObserver;

    private readonly modelDataStore: ModelDataStore;

    private readonly dataFieldListItemDataStore: DataFieldListItemStore;

    constructor(assetApiFunctions: { getModelIds: (modelIds: string[]) => Promise<Model[]>; getModelAssets: (assetIds: string[]) => Promise<ModelAsset[]> }) {
        this.modelDataStore = new ModelDataStore(assetApiFunctions.getModelIds, assetApiFunctions.getModelAssets);
    }

    /** Creates a map and returns a promise when complete */
    async createMap(element: HTMLDivElement, maxBounds: [number, number, number, number], mapBackground: StyleSpecification) {
        return new Promise<void>((resolve) => {
            this.map = new Map({
                container: element,
                style: mapBackground,
                center: [0, 0],
                zoom: 5,
                maxBounds,
                hash: true,
                logoPosition: "bottom-right",
            });

            this.map.once("idle", () => {
                this.onResizeMap(element);
                resolve();
                this.addIventisAttribution();
                this.map.addControl(new NavigationControl());
                this.map.addControl(
                    new GeolocateControl({
                        positionOptions: {
                            enableHighAccuracy: true,
                        },
                        // When active the map will receive updates to the device's location as it changes.
                        trackUserLocation: true,
                    })
                );

                this.map.on("remove", () => {
                    this.remove();
                });
            });
        });
    }

    public getMap() {
        return this.map;
    }

    /** Adds Iventis logo at the bottom of the map */
    private addIventisAttribution() {
        const element = document.getElementsByClassName("mapboxgl-ctrl-bottom-left")[0];
        const imageElement = document.createElement("img");
        imageElement.src = iventisLogo;
        imageElement.style.width = "100px";
        element.appendChild(imageElement);
    }

    /** Add arrow icon to mapbox */
    public addInternalImages() {
        this.loadImage(trianglePNG, "map-internals-triangle", true);
    }

    /** Adds a geojson source to mapbox */
    public addSource(sourceId: string, source: Feature[]) {
        const fc: FeatureCollection = { type: "FeatureCollection", features: source };
        this.map.addSource(sourceId, {
            type: "geojson",
            data: fc,
        });
    }

    /** Adds a layer to mapbox */
    public addLayer(layer: MapboxSupportedLayers) {
        this.map.addLayer(layer);
    }

    /** Adds image to mapbox */
    public loadImage(url: string, id: string, sdf = false) {
        const isImageLoaded = this.map.hasImage(id);
        if (isImageLoaded) {
            return;
        }

        this.map.loadImage(url, (_, image) => {
            if (image == null) {
                return;
            }
            this.map.addImage(id, image, { sdf });
        });
    }

    public addModelLayer(layer: IventisLayer, features: Feature<Geometry, MapObjectProperties>[]) {
        switch (layer.styleType) {
            case StyleType.Model:
                new MapboxModelLayer(this.map, layer as MapModuleLayer, this.createMapEngineState(layer, features), this.modelDataStore, this.dataFieldListItemDataStore, {
                    getAboveLayerId: () => undefined,
                });
                break;
            case StyleType.LineModel:
                new MapboxLineModelLayer(this.map, layer as MapModuleLayer, this.createMapEngineState(layer, features), this.modelDataStore, this.dataFieldListItemDataStore, {
                    getAboveLayerId: () => undefined,
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    updateGuideLine: () => {},
                });
                break;
            default:
            // Do nothing
        }
    }

    /** Move mapbox camera to the given feature */
    public goToFeature(feature: Feature) {
        const bboxFeature = bbox(feature);
        this.map.fitBounds([bboxFeature[0], bboxFeature[1], bboxFeature[2], bboxFeature[3]], { padding: 50, duration: 3000, maxZoom: 19.5 });
    }

    private onResizeMap(mapElement: HTMLDivElement) {
        this.resizeMapObserver = new ResizeObserver(() => {
            this.map.resize();
        });
        this.resizeMapObserver.observe(mapElement);
    }

    private createMapEngineState(layer: IventisLayer, features: Feature<Geometry, MapObjectProperties>[]) {
        return new BehaviorSubject<MapState<MapboxEngineData>>({
            ...initialState.mapModule,
            layers: { value: [layer as MapModuleLayer], stamp: "" },
            geoJSON: { value: { [layer.id]: features.map((f) => ({ feature: f })) } as LocalGeoJson, stamp: "" },
        });
    }

    remove() {
        this.resizeMapObserver.disconnect();
    }
}
