import { Geometry, Point, Feature, LineString, Polygon } from "geojson";
import { DataFieldValueV2 } from "@iventis/domain-model/model/dataFieldValueV2";
import {
    MapImportLayer,
    LineMapImportLayer,
    PointMapImportLayer,
    AreaMapImportLayer,
    IconMapImportLayer,
    ModelMapImportLayer,
    LineModelMapImportLayer,
    MapObjectProperties,
} from "../types/export-types";

export function layerMapObjectsToFeature(layer: MapImportLayer, mapObjectNameDataFieldId: string, level: number): Feature<Geometry, MapObjectProperties>[] {
    let allFeatures: Feature<Geometry, MapObjectProperties>[];
    switch (layer.styleType) {
        case "Area":
            allFeatures = createAreaFeatures(layer, mapObjectNameDataFieldId);
            break;
        case "Line":
            allFeatures = createLineFeatures(layer, mapObjectNameDataFieldId);
            break;
        case "LineModel":
            allFeatures = createLineModelFeatures(layer, mapObjectNameDataFieldId);
            break;
        case "Point":
            allFeatures = createPointFeatures(layer, mapObjectNameDataFieldId);
            break;
        case "Icon":
            allFeatures = createIconFeatures(layer, mapObjectNameDataFieldId);
            break;
        case "Model":
            allFeatures = createModelFeatures(layer, mapObjectNameDataFieldId);
            break;
        default:
            throw new Error(`Unknown style type`);
    }

    return allFeatures.filter((feature) => feature.properties.level === level);
}

function createAreaFeatures(layer: AreaMapImportLayer, mapObjectNameDataFieldId: string) {
    const features: Feature<Geometry, MapObjectProperties>[] = [];
    layer.mapObjects.forEach((mapObject) => {
        const dataFieldValues = createFeatureProperties(layer.id, layer.name, mapObjectNameDataFieldId, mapObject.dataFieldValues, mapObject.id, mapObject.level);
        const feature = createAreaFeature(mapObject.coordinates, dataFieldValues);
        features.push(feature);
    });
    return features;
}

function createAreaFeature(coords: number[][][], properties: MapObjectProperties): Feature<Polygon, MapObjectProperties> {
    return {
        type: "Feature",
        geometry: {
            type: "Polygon",
            coordinates: coords,
        },
        properties,
    };
}

function createLineFeatures(layer: LineMapImportLayer, mapObjectNameDataFieldId: string) {
    const features: Feature<Geometry, MapObjectProperties>[] = [];
    layer.mapObjects.forEach((mapObject) => {
        const dataFieldValues = createFeatureProperties(layer.id, layer.name, mapObjectNameDataFieldId, mapObject.dataFieldValues, mapObject.id, mapObject.level);
        const feature = createLineFeature(mapObject.coordinates, dataFieldValues);
        features.push(feature);
    });
    return features;
}

function createLineModelFeatures(layer: LineModelMapImportLayer, mapObjectNameDataFieldId: string) {
    const features: Feature<Geometry, MapObjectProperties>[] = [];
    layer.mapObjects.forEach((mapObject) => {
        const dataFieldValues = createFeatureProperties(layer.id, layer.name, mapObjectNameDataFieldId, mapObject.dataFieldValues, mapObject.id, mapObject.level);
        // Need level so the features will show in deckgl engine
        dataFieldValues.level = 0;
        const feature = createLineFeature(mapObject.coordinates, dataFieldValues);
        features.push(feature);
    });
    return features;
}

function createLineFeature(coords: number[][], properties: MapObjectProperties): Feature<LineString, MapObjectProperties> {
    return {
        type: "Feature",
        geometry: {
            type: "LineString",
            coordinates: coords,
        },
        properties,
    };
}

function createPointFeatures(layer: PointMapImportLayer, mapObjectNameDataFieldId: string) {
    const features: Feature<Geometry, MapObjectProperties>[] = [];
    layer.mapObjects.forEach((mapObject) => {
        const dataFieldValues = createFeatureProperties(layer.id, layer.name, mapObjectNameDataFieldId, mapObject.dataFieldValues, mapObject.id, mapObject.level);
        const feature = createPointFeature(mapObject.coordinates, dataFieldValues);
        features.push(feature);
    });
    return features;
}

function createIconFeatures(layer: IconMapImportLayer, mapObjectNameDataFieldId: string) {
    const features: Feature<Geometry, MapObjectProperties>[] = [];
    layer.mapObjects.forEach((mapObject) => {
        const dataFieldValues = createFeatureProperties(layer.id, layer.name, mapObjectNameDataFieldId, mapObject.dataFieldValues, mapObject.id, mapObject.level);
        const feature = createPointFeature(mapObject.coordinates, dataFieldValues);
        features.push(feature);
    });
    return features;
}

function createModelFeatures(layer: ModelMapImportLayer, mapObjectNameDataFieldId: string) {
    const features: Feature<Geometry, MapObjectProperties>[] = [];
    layer.mapObjects.forEach((mapObject) => {
        const dataFieldValues = createFeatureProperties(layer.id, layer.name, mapObjectNameDataFieldId, mapObject.dataFieldValues, mapObject.id, mapObject.level);
        dataFieldValues.rotation = mapObject.rotation;
        const feature = createPointFeature(mapObject.coordinates, dataFieldValues);
        features.push(feature);
    });
    return features;
}

function createPointFeature(coords: [number, number], properties: MapObjectProperties): Feature<Point, MapObjectProperties> {
    return {
        type: "Feature",
        geometry: {
            type: "Point",
            coordinates: coords,
        },
        properties,
    };
}

/**
 * Given a map object, create a feature properties for it
 */
function createFeatureProperties(layerId: string, layerName: string, mapObjectNameDataFieldId: string, dataFieldValues: DataFieldValueV2[], objectId: string, level: number) {
    const parsedDataFieldValues: MapObjectProperties = {};
    // Assign basic properties
    parsedDataFieldValues.layerId = layerId;
    parsedDataFieldValues.layerName = layerName;
    parsedDataFieldValues.id = objectId;
    parsedDataFieldValues.level = level;
    // Assign data field values to properties
    dataFieldValues.forEach((dfv) => {
        if (dfv.dataFieldId === mapObjectNameDataFieldId) {
            parsedDataFieldValues.name = getNoneNullDataFieldValue(dfv);
        } else {
            parsedDataFieldValues[dfv.dataFieldId] = getNoneNullDataFieldValue(dfv);
        }
    });
    return parsedDataFieldValues;
}

/** Get the non value value from a DataFieldValueV2 type */
function getNoneNullDataFieldValue(dfv: DataFieldValueV2): string | number | null {
    let val: string | number | null = null;
    Object.entries(dfv).forEach(([key, value]) => {
        if (val == null && key !== "dataFieldId" && value !== null) {
            val = value;
        }
    });
    return val;
}
